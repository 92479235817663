import * as React from 'react';
import {ReactNode} from 'react';

export type Variants = `primary` | `secondary` | `success` | `danger` | `warning` | `info` | `dark` | `light`;

interface BadgeProps {
    cartBadge?: boolean;
    children: ReactNode;
    className?: string;
    createBadge?: boolean;
    e2e?: string;
    noMargin?: boolean;
    onClick?: () => void;
    smallBadge?: boolean;
    variant: Variants;
}

const variantStyles: Record<Variants, any> = {
    primary: {
        bg: `tw-bg-primary`,
        text: `tw-text-white`,
    },
    secondary: {
        bg: `tw-bg-secondary`,
        text: `tw-text-white`,
    },
    success: {
        bg: `tw-bg-success`,
        text: `tw-text-white`,
    },
    danger: {
        bg: `tw-bg-danger`,
        text: `tw-text-white`,
    },
    warning: {
        bg: `tw-bg-warning`,
        text: `tw-text-gray-700`,
    },
    info: {
        bg: `tw-bg-info`,
        text: `tw-text-white`,
    },
    dark: {
        bg: `tw-bg-dark`,
        text: `tw-text-white`,
    },
    light: {
        bg: `tw-bg-light`,
        text: `tw-text-gray-700`,
    },
};

export const Badge = ({
    cartBadge = false,
    children,
    className,
    createBadge = false,
    e2e,
    noMargin = false,
    onClick,
    smallBadge = false,
    variant,
}: BadgeProps) => {
    const {bg, text} = variantStyles[variant];

    /**
     * Template
     */
    return (
        <span
            data-e2e={e2e}
            className={`tw-inline-flex tw-justify-center tw-items-center ${bg} ${text} tw-uppercase tw-text-xs tw-font-bold tw-mr-2 tw-rounded-sm${
                noMargin ? ' tw-mr-0' : ' tw-mr-2'
            }${
                cartBadge
                    ? ' tw-inline-block !tw-rounded-[10rem] tw-font-bold tw-leading-none tw-text-center tw-whitespace-nowrap tw-align-baseline tw-py-[.25em] tw-px-[.6em]'
                    : ''
            }${smallBadge ? ' tw-px-1 tw-py-0.5' : ' tw-px-2 tw-py-1'} ${className}`}
            onClick={onClick}
            style={createBadge ? {textTransform: `unset`} : {}}
        >
            {children}
        </span>
    );
};
